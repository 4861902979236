<template>
    <v-card
        :flat="flat">
        <v-card-text>
            <v-row>
                <v-col align="center">
                    <v-btn
                        v-if="!flat"
                        class="float-right mt-n8"
                        icon
                        color="warning"
                        @click="toggleAction()">
                        <v-icon>
                            mdi-close-circle
                        </v-icon>
                    </v-btn>
                    <a
                        :href="randomBanner.link"
                        target="_blank">
                        <img style="max-width: 800px" :src="randomBanner.banner"/>
                    </a>

<!--                    <template v-if="randomIntFromInterval(1, 2) % 2 === 0">-->
<!--                        <a href="https://growlab.ai" target="_blank">-->
<!--                            <img v-if="$vuetify.breakpoint.xsOnly" style="max-width: 100%" :src="require('@/assets/banners/ai_ad.png')"/>-->
<!--                            <img v-else style="max-width: 100%" :src="require('@/assets/banners/ai_ad_banner.png')"/>-->
<!--                        </a>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                        <a href="https://neuralnode.ai/" target="_blank">-->
<!--                            <img style="max-width: 100%" :src="require('@/assets/banners/nn_ai_ad_banner.png')"/>-->
<!--                        </a>-->
<!--&lt;!&ndash;                        <Adsense&ndash;&gt;-->
<!--&lt;!&ndash;                            style="display:block"&ndash;&gt;-->
<!--&lt;!&ndash;                            :data-ad-client="client"&ndash;&gt;-->
<!--&lt;!&ndash;                            :data-ad-slot="adSlot"&ndash;&gt;-->
<!--&lt;!&ndash;                            :data-ad-format="format">&ndash;&gt;-->
<!--&lt;!&ndash;                        </Adsense>&ndash;&gt;-->
<!--                    </template>-->
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    /**
     * @group Ads
     * A general ad component that we can call for ads and the "upsell" close icon.
     */
    export default {
        data() {
            return {
                // @vuese
                // Client ID provided by Google Adsense.
                client: 'ca-pub-2090106487060356',
                randomBanner: null
            }
        },
        computed: {
            hasCountryAd() {
                const countryList = [
                    'Canada'
                ]
                return this.$session.has('country') &&
                    this.$_.includes(countryList, this.$session.get('country'))
            }
        },
        props: {
            // Ad slot ID, comes from Google adsense.
            adSlot: {
                type: String,
                required: true
            },
            // Ad format, comes from Google adsense.
            format: {
                type: String,
                required: false,
                default: 'horizontal'
            },
            flat: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            /**
             * @vuese
             * Used to direct the user to the sign in page.
             */
            toggleAction() {
                this.$EventBus.$emit('showUpSellGold')
                this.$gtag.event('click', {
                    'event_category': 'ad',
                    'event_label': 'Dismiss Ad',
                    'value': true
                })
            },
            pickRandomAd() {
                return Math.floor(Math.random() * 4) + 1+'-EARN50N30S.png'
            },
            getRandomBanner() {
                const banners = [
                    // {
                    //     link: 'https://neuralnode.ai/',
                    //     banner: require('@/assets/banners/nn_ai_ad_banner.png')
                    // },
                    // {
                    //     link: 'https://growlab.ai/?ref=8b9f9b8e6d1a4a6f',
                    //     banner: require('@/assets/banners/ai_ad_banner.png')
                    // },
                    {
                        link: 'https://app.getgrass.io/register/?referralCode=d5zsZMoy2Zj2CLv',
                        banner: require('@/assets/banners/grass_ad_banner.png')
                    },
                    {
                        link: 'https://cmusic.ai/',
                        banner: require('@/assets/banners/cms_ad_banner.png')
                    }
                ]
                const random = this.randomIntFromInterval(0, banners.length - 1)
                return banners[random]
            },
            randomIntFromInterval(min, max) { // min and max included
                return Math.floor(Math.random() * (max - min + 1) + min)
            }
        },
        created() {
            this.randomBanner = this.getRandomBanner()
        }
    }
</script>