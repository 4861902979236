<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h1 class="font-weight-regular">
                    About the Browser Miner 
                </h1>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="6725597086"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-text class="pa-12">
                        <v-sheet
                            max-width="900px"
                            style="margin: 0 auto;">
                            <v-row>
                                <v-col>
                                    <p class="mb-0">
                                        XMR Mining Online – Our Monero Browser-based GUI was created to allow users to get involved in the mining of the cryptocurrency Monero. Mining can be fun and a great hobby, not to mention you’re rewarded with Crypto Currencies for your computer solving transactions.  With our browser-based miner application, you can mine Monero from almost any device that has a Web browser.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row align="center">
                                <v-col
                                    class="text-center"
                                    cols="12"
                                    sm="auto">
                                    <img
                                        width="150px"
                                        :src="require('@/assets/logo.png')">
                                </v-col>
                                <v-col>
                                    <p>
                                        To start XMR mining online all you have to do is create a free account, add your Monero (XMR) wallet address under settings, click on the miner link and click start on the miner.  As long as you keep the miner browser window open the miner will utilize the computer’s CPU power to solve transactions and reward you with the Cryptocurrency Monero (XMR).
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <p class="mb-0">
                                        The easy to use user interface displays data such as Pool Hash Rate, Network Hash Rate, Network Difficulty, Connected Miners, Last Network Block Reward and it even has a graph so you can see your Hashes Per Second performance.  There is also an option to change your Threads for the advanced users.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-btn
                                        x-large
                                        color="warning"
                                        to="/sign-up">
                                        Create My Account
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="3484124564"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Ad from './../components/ads/GeneralAd';

    export default {
        metaInfo: {
            titleTemplate: 'About the Browser Miner - %s',
            meta: [
                { charset: 'utf-8' },
                {
                    property: 'description',
                    content: 'XMR Mining online, browser based xmr miner, online monero miner, browser based monero miner, mining xmr online, easy xmr mining online, xmr online',
                }
            ]
        },
        components: {
            Ad
        }
    }
</script>
